/* Your custom fonts here */

/* Example

@font-face {
    font-family: 'CustomFont';
    src: url('../fonts/Custom-Font.eot');
    src: url('../fonts/Custom-Font.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Custom-Font.woff') format('woff'),
         url('../fonts/Custom-Font.svg#custom_font') format('svg');
    font-weight: 400;       // For normal width. It could has another value   
    font-style: normal;     // Also could has another value
    }


// Var for using custom font
$CustomFont: 'CustomFont', Helvetica, Arial, sans-serif;
*/