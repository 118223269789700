.footer {
  color: #fff;
  font-size: 16px;
  line-height: 35px;

  &__main {
    padding-top: 22px;
    padding-bottom: 40px;
    background-color: #000;
  }
  &__group {    
    display: grid;
    grid-template-columns: 5fr 3fr 4fr;
    grid-column-gap: 24px;
  }
  &__logo {
    margin-top: 34px;
  }
  &__info  {
    max-width: 370px;
    margin-top: 16px;
  }
  &__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }
  &__bottom {
    padding-top: 19px;
    padding-bottom: 16px;
    background: #191919;
  }
  &__bottom-group {
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-column-gap: 12px;
  }
  &__bottom-inner {
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-column-gap: 24px;
  }
  &__bottom-bl {
    width: 50%;
  }
  &__link {
    display: inline-flex;
    align-items: center;
    .ic {
      margin-right: 9px;
    }
  }

  .ui-link {
    color: #fff;
    &:after {
      background-color: #fff;
    }
  }

  @media (max-width:1023.98px) {
    &__logo {
      margin-top: 28px;
    }
    &__group {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      grid-column-gap: 0;
    }
    &__item {
      width: calc(50% - 10px);
      &.st-main {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    &__info  {
      max-width: 100%;
      width: calc(50% - 10px);
      margin-top: 25px;
      font-size: 16px;
      line-height: 19.5px;
    }
    &__list {
      margin-top: 22px;
    }
    &__main {
      padding-bottom: 20px;
    }

    &__bottom-group {
      grid-template-columns: 6fr 6fr;
    }
  }



  @media (max-width:767.98px) {
    &__item.st-main {
      display: block;
      text-align: center;
    }
    &__info {
      width: 100%;
      margin-top: 18px;
    }
    &__logo {
      margin-top: 35px;
    }
    &__list {
      margin-top: 38px;
      font-size: 14px;
      line-height: 17.07px;

      .ui-link {
        margin-bottom: 15px;
      }
    }
    &__main {
      padding-bottom: 32px;
    }
    &__bottom-inner {
      display: block;
    }
  }
}
