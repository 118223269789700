body {
  font: 400 18px/1.2 "Montserrat", sans-serif;
  color: #000;
}

strong {
  font-weight: 700;
}

.container {
  width: 100%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.section-default {
  padding-top: 82px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.ui-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 1px;
    margin-top: 3px;
    transition: all .3s;
  }

  &:hover {
    text-decoration: none;
    &:after {
      width: 100%;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 19px 59px;
  border-radius: 50px;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  box-shadow: 0 4px 10px 0 #00000040;
  transition: all .3s;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #2096CD;
  box-shadow: 0 2px 6px 0 #00000040;

  &:hover {
    background-color: #19749f;
  }
}
.btn-sm {
  padding: 12px 30px;
  font-size: 14px;
  line-height: 12.68px;
}

.ui-title {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
  &__subtitle {
    margin-top: 6px;
    font-size: 36px;
  }
}

.text-primary {
  color: #2096CD;
}

.ui-tabs-nav {
  padding: 5px 7px;
  border-radius: 20px;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &__btn {
    padding-left: 13px;
    padding-right: 13px;
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    transition: all .3s;
    border-radius: 20px;
    border: none;
    background-color: #fff;
    &.active {
      padding-left: 28px;
      padding-right: 28px;
      background-color: #2096CD;
      color: #fff;
      font-weight: 700;
    }
    &:not(.active) {
      cursor: pointer;
    }
    &:hover {
      background-color: #2096CD;
      color: #fff;
    }

    + .ui-tabs-nav__btn {
      margin-left: 7px;
    }
  }
}

.ui-tabs-main {
  display: flex;
  justify-content: center;
}

.ui-tabs-panel {
  display: none;
  opacity: 0;
  transform: translateY(-20px);
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease; 

  &.active {
    display: block;
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

.ui-accordion {
  &__item {
    margin-bottom: 30px;
    border-radius: 20px;
    &.st-show {
      box-shadow: 0px 0px 10px 0px #00000040;

      .ui-accordion {
        &__header {
          &:after {
            transform: rotate(0);
          }
        }
      }
    }
  }
  &__header {
    position: relative;
    width: 100%;
    padding: 30px;
    background: #2096CD;
    border: none;
    border-radius: 20px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    transition: all .3s;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      right: 30px;
      top: 30px;
      width: 18px;
      height: 18px;
      background: url('data:image/svg+xml,<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 17L8 9L2 1" stroke="white" stroke-width="3"/></svg>') 50% no-repeat;
        transform: rotate(90deg);
      transition: all .3s;
    }
    &:hover {
      background-color: #19749f;
    }
  }
  &__body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease, padding 0.4s ease;
    p {
      margin: 0;
      font-size: 18px;
      line-height: 35px;
    }
  }
  &__content {
    padding: 30px;
  }
}


.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4); 
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease; 
}

.modal.show {
    opacity: 1;
    visibility: visible;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  padding: 20px 30px;
  border: 1px solid #888;
  width: 90%; 
  max-width: 600px;
  transition: transform 0.3s ease; 
  transform: translateY(-20px); 
  overflow-y: auto;
  max-height: 90vh;
  scrollbar-color: #2096CD #fff;  

  &::-webkit-scrollbar {
    -webkit-appearance: none;
          height: 5px;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2096CD;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
}

.modal.show .modal-content {
    transform: translateY(0); 
}

.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  transition: all 0.3s; 
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.ui-carousel {
    position: relative;
    margin-left: -12px;
    margin-right: -12px;
    padding-bottom: 50px;
}

.ui-carousel-inner {
    overflow: hidden;
}
.ui-carousel-wrap {
    display: flex;
    transition: transform 0.5s ease;
}

.ui-carousel-item {
    flex: 0 0 auto;
    width: calc(100% / var(--slides-to-show));
    user-select: none;
    padding-left: 12px;
    padding-right: 12px;

    &:active {
      cursor: grabbing;
    }

  @media (max-width: 768px) {
    width: 100% !important;
  }
}


.ui-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.ui-dot {
    width: 10px;
    height: 10px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
}

.ui-dot.active,
.ui-dot:hover {
    background-color: #2096CD;
    transition: all .3s;
}

.ui-arr-prev, .ui-arr-next {
    position: absolute;
    top: 50%;
    padding: 10px;
    border: none;
    background-color: transparent;
    font-size: 36px;
    font-weight: bold;
    color: #2096CD;
    transform: translateY(-50%);
    cursor: pointer;
}
.ui-arr-prev.st-disabled, 
.ui-arr-next.st-disabled {
  opacity: 0.5;
}

.ui-arr-prev {
    left: -40px;
}

.ui-arr-next {
    right: -40px;
}



.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 32px;
  // background-color: #051156;
  z-index: 10;
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__trigger {
    display: none;
  }
  &__links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 10px;
  }
  &__link {
    display: inline-flex;
    margin-left: 40px;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
  &__mob-nav,
  &__logo-mob {
    display: none;
  }
  &__trigger {
    position: absolute;
    right: 20px;
    transition: all .3s;
  }

  .ui-link {
    color: #fff;
    &:after {
      background-color: #fff;
    }
  }
}

.b-main {
  padding-top: 184px;
  padding-bottom: 110px;
  color: #fff;
  &__title {
    margin-top: 0;
    margin-bottom: 57px;
    font-size: 52px;
    font-weight: 700;
    line-height: 63.39px;
  }
  &__info {
    position: relative;
    margin-bottom: 78px;
    padding-left: 43px;
    font-size: 36px;
    line-height: 43.88px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      background: linear-gradient(180deg, #FFF649 0%, #FF7C78 100%);
    }
  }
  &__group {
    display: inline-block;
    text-align: center;
  }
  &__note {
    margin-top: 22px;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 18px;
  }
}

.b-total {
  margin-top: -63px;
  padding-top: 140px;
  padding-bottom: 96px;
  background-color: #000;
  color: #fff;
  clip-path: polygon(0 18%, 100% 0, 100% 100%, 0% 100%);

  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 24px;
  }
  &__item {
    text-align: center;
  }
  &__number {
    font-size: 46px;
    font-weight: 700;
    line-height: 1;
  }
  &__info {
    font-size: 24px;
    line-height: 29.26px;
  }
}

.b-advant {
  position: relative;
  margin-top: 5px;
  padding-bottom: 90px;
  background-color: #231159;
  color: #fff;
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(180deg, #FFF649 0%, #FF7C78 100%);
  }
  &__info {
    margin-bottom: 64px;
    font-size: 24px;
    line-height: 35px;
  }
  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 27px;
    grid-row-gap: 103px;
  }
  &__item {
    text-align: center;

    .ic {
      margin-bottom: 16px;
    }

    &.st-center {
      align-content: center;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 35px;
  }

  .ui-title {
    text-align: center;
  }
}

.b-map {
  padding-top: 100px;
  padding-bottom: 88px;
  background-color: #000;
  color: #fff;
  text-align: center;

  .ui-title {
    margin-bottom: 100px;
  }
}

.b-seo {
  padding-bottom: 63px;
  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
    margin-top: 82px;
    margin-bottom: 80px;
  }
  &__item {
    position: relative;
    text-align: center;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        right: -20px;
        top: 35px;
        width: 16px;
        height: 28px;
        background: url('data:image/svg+xml,<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 26L12 14L2 2" stroke="%232096CD" stroke-width="5"/></svg>');
      }
    }
  }
  &__number {
    margin-bottom: 12px;
    font-size: 80px;
    font-weight: 700;
    color: #2096CD;
  }
  &__title {
    margin-bottom: 25px;
    font-size: 36px;
    font-weight: 700;
    line-height: 35px;
  }
  &__fig-2 {
    border: 8px solid #2096CD;
    border-radius: 20px;
    overflow: hidden;
  }
  &__block {
    display: flex;
  }
  &__block-main {
    position: relative;
    flex-grow: 1;
    padding-top: 20px;
    padding-right: 42px;
    text-align: center;
  }
  &__block-img {
    margin-top: -22px;
  }
  &__fig-1 {
    margin-top: -9px;
  }
  &__arr-right {
    position: absolute;
    right: 46px;
    top: 92px;
    display: flex;

    .ic {
      margin-right: -2px;
      animation: move 2s infinite;
      opacity: 0.2;
      transition: all .2s;
    }

    .ic:nth-child(1) {
      animation-delay: 0s;
    }
    .ic:nth-child(2) {
      animation-delay: .5s;
    }
    .ic:nth-child(3) {
      animation-delay: 1s;
    }
  }

  &__arr-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    .ic {
      margin-top: -2px;
      animation: move 2s infinite;
      opacity: 0.2;
      transition: all .2s;
    }

    .ic:nth-child(1) {
      animation-delay: 0s;
    }
    .ic:nth-child(2) {
      animation-delay: .5s;
    }
    .ic:nth-child(3) {
      animation-delay: 1s;
    }
  }

  .ui-title {
    text-align: center;
  }
}

@keyframes move {
  0%, 25% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}



.b-price {
  position: relative;
  margin-top: 5px;
  padding-bottom: 150px;
  background-color: #231159;
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(180deg, #FFF649 0%, #FF7C78 100%);
  }
  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 24px;
    margin-top: 68px;
  }
  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px 32px;
    border-radius: 20px;
    background-color: #fff;
  }
  &__label {
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background-color: #2096CD;
    border-radius: 0 0 20px 20px;
  }
  &__title {
    min-height: 58px;
    margin-bottom: 23px;
    font-size: 36px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
  }
  &__title-text {
    display: inline-block;
    margin-left: 5px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
  }
  &__title-note {
    margin-top: 6px;
    font-size: 14px;
    line-height: 17.07px;
    font-weight: 400;
  }
  &__title-old {
    text-decoration: line-through;
  }
  &__table {
    margin-bottom: 34px;
  }
  &__tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
    font-size: 16px;
    line-height: 19.5px;
  }
  &__td + .b-price__td {
    padding-left: 12px;
  }
  &__wrap {
    flex-grow: 1;
  }
  &__subtitle {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
  }
  &__note {
    font-size: 14px;
    line-height: 17.07px;
  }
  &__list {
    list-style-type: none;
    padding-left: 0;
    font-size: 14px;
    line-height: 17.07px;
    li {
      margin-bottom: 8px;
      &:before {
        content: "+ ";
        color: #2096CD;
        font-weight: bold;
      }
    }
  }

  .ui-title {
    margin-bottom: 12px;
    color: #fff;
    text-align: center;
  }

  .ui-tabs-nav {
    margin-top: 30px;
  }
}

.b-reviews {
  padding-bottom: 122px;
  background: #000 url(../img/content/b-reviews/bg.webp) 50% no-repeat;
  background-size: cover;
  &__slider {
    margin-top: 73px;

  .ui-arr-prev, 
  .ui-arr-next {
    margin-top: -27px;
  }

  }
  &__item {
    display: flex;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;

    + .b-reviews__item {
      margin-top: 43px;
    }
  }
  &__link {
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.07px;
    color: #2096CD;
    transition: all .3s;
    cursor: pointer;

    &:after {
      background-color: #19749f;
    }
  }
  &__img {
    flex: 0 0 auto;
    width: 112px;
    aspect-ratio: 112/170;
    object-fit: cover;
    border-radius: 20px;
    overflow: hidden;
  }
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-top: 5px;
    padding-left: 20px;
  }
  &__info {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;    
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      margin-top: 0;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__block {
    padding-right: 16px;
  }
  &__author {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
  }
  &__categorie {
    font-size: 16px;
    line-height: 19.5px;
  }

  .ui-title {
    margin-bottom: 40px;
    color: #fff;
    text-align: center;
  }
}


.b-faq {
  padding-top: 95px;
  padding-bottom: 79px;

  .ui-accordion {
    max-width: 1096px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

.b-form {
  padding-bottom: 73px;
  color: #fff;
  background: #231159;

  &__main {
    margin-top: 68px;
  }
  &__group {
    margin-bottom: 50px;
  }
  &__control {
    width: 648px;
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 24px;
    line-height: 56px;
    text-align: center;
    border: none;
  }
}

.input-err {
  padding-top: 6px;
  color: red;
  font-size: 14px;
}

.text-nowrap {
  white-space: nowrap;
}



@media (max-width:1439.98px) {
  .container {
    padding-left: 56px;
    padding-right: 56px;
  }
  .b-price__group {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
  }
  .b-total__group {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 64px;
  }
  .ui-carousel {
    margin-left: 0;
    margin-right: 0;
  }
  .b-total {
    margin-top: 0;
    padding-top: 70px;
    padding-bottom: 63px;
    clip-path: none;
  }
}

@media (max-width:1023.98px) {
  .header {
    position: fixed;
    transition: all .3s;

    &.fixed {
      padding-top: 5px;
      background-color: #231159;
      box-shadow: 0 0 5px rgba(255, 255, 255, .7);
      .header__trigger {
        top: 4px;
      }
      .header__logo {
        max-width: 200px;
      }
    }
    &__mob-nav,
    &__logo-mob {
      display: block;
    }
    &__logo-mob {
      margin-left: 20px;
      margin-top: 34px;
    }
    &__trigger {
      position: absolute;
      right: 50px;
      top: 38px;
      display: block;
      z-index: 10;
      background-color: transparent;
      border: none;

      .st-hide {
        display: none;
      }
      &.active .st-hide {
        display: inline;
      }
      &.active .st-show {
        display: none;
      }
    }

    &__links {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      opacity: 0;
      transition: opacity 0.5s ease;
      z-index: 9;
      background-color: #fff;
      overflow-y: auto;

    }
    &__links.show {
      display: block;
      opacity: 1;
      margin-right: 0;
    }
    &__mob-nav {
      padding-left: 0;
      list-style-type: none;
    }
    &__link {
      display: block;
      color: #000;
      font-size: 24px;
      line-height: 58px;

      &.st-languages {
        margin-top: 16px;
      }
    }

    .ui-link {
      color: #000;
    }
  }

  .ui-title {
    font-size: 32px;
    &__subtitle {
      margin-top: 0;
      font-size: 24px;
    }
  }
  .section-default {
    padding-top: 50px;
  }

  .b-seo {
    padding-bottom: 55px;
    &__number {
      font-size: 60px;
    }
    &__title {
      font-size: 32px;
    }
    &__group {
      display: block;
      margin-top: 42px;
      margin-bottom: 37px;
    }
    &__item:not(:last-child):after {
      position: static;
      display: inline-block;
      transform: rotate(90deg);
      margin-top: 24px;
      margin-bottom: 14px;
    }
    &__info {
      max-width: 610px;
      margin: 0 auto;
    }
    &__block {
      display: block;
    }
    &__block-main {
      padding-right: 0;
      .b-seo__info {
        max-width: 100%;
      }
    }
    &__arr-right {
      display: none;
    }
    &__block-img {
      margin-top: 30px;
      text-align: center;
    }
  }

  .b-main {
    padding-top: 144px;
    padding-bottom: 55px;
    background-size: 170%;
    &__title {
      font-size: 36px;
      line-height: 43.88px;
    }
    &__info {
      margin-bottom: 62px;
      padding-left: 29px;
      font-size: 24px;
      font-weight: 400;
      line-height: 29.26px;
    }
    &__group {
      display: block;
    }
  }

  .b-total {
    &__group {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .b-advant {
    padding-top: 68px;
    padding-bottom: 62px;
    &__info {
      margin-top: 40px;
      font-size: 18px;
      line-height: 21.94px;
    }
    &__text {
      line-height: 21.94px;
    }
    &__group {
      grid-row-gap: 65px;
    }
    &__item {
      .btn {
        margin-top: 60px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .b-map {
    padding-bottom: 53px;
    .ui-title {
      margin-bottom: 50px;
    }
  }

  .b-price {
    padding-top: 60px;
    padding-bottom: 60px;

    &__group {
      margin-top: 52px;
    }
    &__item {
      width: 312px;
    }
    &__list {
      margin-bottom: 30px;
    }

    .ui-tabs-nav {
      margin-top: 13px;
    }
  }

  .b-reviews {
    padding-top: 70px;
    padding-bottom: 60px;
    
    &__slider {
      margin: 50px 20px 0;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 70px;
    }
  }

  .b-faq {
    padding-top: 68px;
    padding-bottom: 36px;

    .ui-accordion {
      margin-top: 53px;
    }
  }

  .b-form {
    padding-top: 62px;
    padding-bottom: 58px;

    &__main {
      margin-top: 42px;
    }
    &__control {
      font-size: 18px;
      line-height: 56px;
    }
    &__group {
      margin-bottom: 38px;
    }
  }

  .ui-carousel-item {
    padding-left: 0;
    padding-right: 0;
  }

  .ui-accordion__body p {
    font-size: 16px;
    line-height: 19.5px;
  }
}


@media (min-width:768px) {
  .b-main {
    background: url(../img/content/b-main/bg.webp) 50% 0 no-repeat;
  }  
}

@media (min-width:1920px) {
  .b-main {
    background-size: cover;
  }
}


@media (max-width:767.98px) {
  body {
    font-size: 16px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .btn:not(.btn-sm) {
    padding: 15px 30px;
    font-size: 18px;
    line-height: 22px;
  }
  .ui-title {
    font-size: 28px;
    text-align: center;

    &__subtitle {
      margin-top: 10px;
      font-size: 18px;
    }
  }
  .header {
    &__logo {
      max-width: 253px;
    }
    &__trigger {
      right: 10px;
    }
  }

  .section-default {
    padding-top: 60px;
  }

  .b-main {
    padding-top: 124px;
    padding-bottom: 45px;
    background: url(../img/content/b-main/bg_sm.webp) 50% 0 no-repeat; 
    &__title {
      margin-bottom: 202px;
      font-size: 28px;
      line-height: 34.13px;
      text-align: center;
    }
    &__info {
      margin-bottom: 35px;
      padding-left: 0;
      text-align: center;
      font-size: 18px;
      line-height: 21.94px;

      &:before {
        content: none;
      }
    }
    &__note {
      margin-top: 30px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .b-total {
    padding-top: 58px;
    padding-bottom: 53px;
    &__group {
      grid-row-gap: 34px;
      padding-left: 0;
      padding-right: 0;
    }
    &__number {
      font-size: 28px;
      line-height: 34.13px;
    }
    &__info {
      font-size: 15px;
      line-height: 18.29px;
    }
  }

  .b-advant {
    padding-top: 43px;
    padding-bottom: 20px;
    &__group {
      display: block;
    }
    &__info {
      margin-top: 30px;
      margin-bottom: 43px;
      font-size: 16px;
      line-height: 19.5px;
    }
    &__item {
      margin-bottom: 40px;

      .btn {
        margin-top: 0;
      }
    }
  }

  .b-seo {
    padding-top: 40px;

    &__group {
      margin-top: 33px;
      margin-bottom: 47px;
    }
    &__title {
      margin-bottom: 14px;
      font-size: 24px;
      line-height: 32.1px;
    }
    &__item:not(:last-child):after {
      margin-top: 31px;
      margin-bottom: 18px;
    }
    &__fig-1 {
      margin-left: -20px;
      margin-right: -20px;
      max-width: calc(100% + 40px);
    }
    &__block-main {
      padding-top: 35px;
    }
    &__arr-top {
      margin-bottom: 30px;
    }
  }

  .ui-tabs-nav {
    width: 100%;
    &__btn {
      flex-grow: 1;
      padding: 8px;
      font-size: 14px;
      line-height: 1.2;
      &.active {
        padding: 8px;
      }
    }
  }

  .b-price {
    padding-bottom: 10px;
    &__group {
      display: block;
    }
    &__wrap {
      margin-bottom: 25px;
    }
    &__item {
      margin-bottom: 50px;
    }
    &__list {
      margin-bottom: 0;
    }
  }

  .b-reviews {
    &__slider {
      margin-top: 23px;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 28px;

      .ui-arr-next,
      .ui-arr-prev {
        display: none;
      }
    }

    &__item + .b-reviews__item {
      margin-top: 30px;
    }
    &__wrap {
      display: block;
    }
    &__link {
      margin-top: 15px;
    }
    &__info,
    &__author,
    &__categorie {
      font-size: 14px;
      line-height: 17.07px;
    }

    .b-faq {
      padding-top: 50px;
    }

    .ui-title {
      margin-bottom: 20px;
    }
  }

  .b-form {
    padding-top: 50px;
    padding-bottom: 50px;
    &__control {
      width: 100%;
    }
    &__group {
      margin-bottom: 28px;
    }
  }

  .b-faq {
    padding-top: 48px;
    
    .ui-accordion {
      margin-top: 30px;
    }
  }

  .ui-accordion {
    &__header {
      padding: 22px 50px 24px 30px;
      font-size: 18px;
      &:after {
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__item {
      margin-bottom: 20px;

      &.st-show .ui-accordion__header:after {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }
}


@media (max-width:767.98px) and (min-width:428px) {
  .b-main {
    background: url(../img/content/b-main/bg_sm.webp) 50% 27% no-repeat;
    background-size: cover;
  }
}
